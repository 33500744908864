import { Link } from "gatsby";
import * as React from "react";
import { Grid, Header, List, Segment } from "semantic-ui-react";
import latticeLogo from "../../../data/img/lattice-logo-2019-noText.svg";
import latticeFullLogo from "../../../data/img/lattice-logo-2019-newText-lightBackground-whiteText.png";
import { MenuProps } from "../Menu";

interface FooterProps extends MenuProps {}

export default () => (
  <Segment className="footer" padded="very">
    <Grid relaxed doubling stackable className="container">
      <Grid.Row>
        <Grid.Column className="left-side" width={12}>
          <Header as="h4">LATTICE AUTOMATION</Header>
          <p className="footer-about">
            We make software solutions that enable synthetic biology workflows,
            pipelines, and best practices. We use experimental data and
            institutional knowledge to enable bio-design productivity and
            learning.
          </p>
          
        </Grid.Column>

        <Grid.Column className="right-side" width={4}>
          <div>
            <div className="link">
              <Link to="/solutions">Solutions</Link>
            </div>
            <div className="link">
              <Link to="/team">Team</Link>
            </div>
            <div className="link">
              <Link to="/contact">Contact</Link>
            </div>
          </div>
          {/* <div className="logo-div">
            <img alt="Lattice logo" src={latticeLogo} className="footer-logo" />
          </div> */}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="copyright-row">
        <Grid.Column width={8} floated="left">
          <List>
            <label>
              <span>Email </span>
              <a href="mailto:contact@latticeautomation.com" target="_top">
                contact@latticeautomation.com
              </a>
            </label>
          </List>
          <p>Copyright &#9400; 2022 Lattice Automation, Inc.</p>
          <p>LATTICE and FOUNDRY IN A BOX are service marks of Lattice Automation, Inc.</p>
        </Grid.Column>
         
        <Grid.Column floated="right" width={6} className="right-side">
          <div className="logo-div">
            <img alt="Lattice logo" src={latticeFullLogo} className="footer-logo" />
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
);
